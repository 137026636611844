<template>
  <v-app>
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;300;400;500&display=swap" rel="stylesheet">      
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
    <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css"/>

<v-main>
      <div class="banner">
      <div class="hidden-md-and-up">
        <img
        class= "image-mobile"
        :src="require('.././assets/logo_sfelec_transparent.png')"
        />           
      </div>
      <div class="hidden-sm-and-down">
        <v-img
        class= "image"
        :src="require('.././assets/logo_sfelec_transparent.png')"
        contain
        />           
      </div>

        <v-img
        class="background"
        :src="require('.././assets/wire.jpg')"
        height="300"
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        >
        <div class="icon">
          <v-icon class="phone-icon-banner" color="blue" large>phone</v-icon> 
          <a class="phone-texte-banner" href="tel:+32476265226">0476 26 52 26</a>
          <v-icon class="mail-icon-banner" color="blue" large>mail</v-icon> 
          <a class="mail-texte-banner" href="mailto:info@sf-elec.be">info@sf-elec.be</a>
        </div>   
        </v-img>    
          
      </div>
          
      <br>

      <h1 class="text-center" href="#top">Bienvenue sur le site de SF ELEC</h1>
      <h3 class="text-center second-title">Votre électricien dans tout le Brabant Wallon</h3>

      <v-row class="text-center">
        <v-col class="justify-center" 
        :sm="4" 
        :cols="12">
        <v-img
            :src="require('.././assets/boite.svg')"
            class="my-3"
            contain
            height="150"
            >
              <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="blue lighten-3"
                ></v-progress-circular>
              </v-row>
              </template>
            </v-img>
        <h2>Dépannage électrique</h2>
        </v-col>

        <v-col class="justify-center" 
        :sm="4" 
        :cols="12">
        <v-img
            :src="require('.././assets/document.svg')"
            class="my-3"
            contain
            height="150"
            >
              <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="blue lighten-3"
                ></v-progress-circular>
              </v-row>
              </template>
            </v-img>
        <h2>Remise en conformité</h2>
        </v-col>

        <v-col class="justify-center" 
        :sm="4" 
        :cols="12">
        <v-img
            :src="require('.././assets/house.svg')"
            class="my-3"
            contain
            height="150"
            >
              <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="blue lighten-3"
                ></v-progress-circular>
              </v-row>
              </template>
            </v-img>
        <h2>Installation</h2>
        </v-col>
      </v-row>
      
      <v-container>
      <div class="text-center video-seb video-responsive hidden-md-and-up">
        <iframe width="560" height="415" src="https://www.youtube.com/embed/zqG4GZeS97c?autoplay=1&mute=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <div class="text-center video-seb hidden-sm-and-down">
        <iframe width="660" height="415" src="https://www.youtube.com/embed/zqG4GZeS97c?autoplay=1&mute=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      </v-container>

      <!-- <v-container> -->

      <!-- <div class="text-info"> -->
      <!-- <v-row class="text-justify flex-column flex-sm-row"> -->
        <!-- <v-col :sm="3"
        :cols="12"> -->
          <div data-aos="fade-down" class="p1">
            Aujourd'hui la majorité des objets nécessitent une alimentation électrique, c'est pourquoi avoir une installation correspondant à ses besoins est devenu crucial.
            <br>
            <br>
            La technologie évolue et nous permet de faire de réels changements dans les installations électriques, que ce soit au point de vue de la sécurité (respect des normes RGIE), que de l'économie d'énergie ; passage au led, adaptation de l'installation aux besoins réels, automatisation...etc. 
          <!-- </div> -->
        <!-- </v-col> -->

        <!-- <v-col :sm="3"
        :cols="12"> -->
          <!-- <div data-aos="fade-down" class="p1"> -->
            Sans oublier l'esthétique ; interrupteurs et prises jaunies ? En mauvais état ? D'autres modèles sur le marché vous plaisent d'avantage ? Il existe une myriade de marques différentes proposant des alternatives au matériel standard tout en étant aussi fiable qualitativement. 
          </div>
        <!-- </v-col>

        <v-col :sm="3"
        :cols="12"> -->
          <div data-aos="fade-down" class="p3">
          <h2 class="title-p">Pourquoi appeler un électricien ?</h2>
          <br>
          L'électricité est partout, on l'utilise tellement qu'on en oublie parfois que les règles de sécurité en vigueur sont là pour nous protéger. Ne prenez aucun risque et appelez au moindre doute, vous saurez rapidement si cela nécessite réellement une modification. 
          <br>
          <br>
          La plupart des objets connectés ou raccordés nécessitent un savoir-faire lors de l'installation pour durer et être correctement mis en marche. Les "bricolages" sont souvent source de problèmes.
          </div>     
        <!-- </v-col>
  
        <v-col :sm="3" 
        :cols="12"> -->
          <div data-aos="fade-down" class="p4">
             Néanmoins, installer du matériel électrique n'est pas le seul rôle de l'électricien, ainsi il est indispensable de connaître suffisamment les défauts des appareils et des installations afin de rapidement identifier les causes des pannes et donc être efficace dans le dépannage. 
             <br>
             <br>
             Tout comme il faut connaître les mal-façons récurrentes, les changements de règles ou de lois pour pouvoir assurer qu'une installation est en conformité avec le RGIE.
            <br><br>
            <p class="important-txt">En bref, il faut appeler quelqu'un de qualifié quand on touche à l'électricité !</p>
          </div>
        <!-- </v-col>
      </v-row> -->
      <div class="pinfo">
      <v-row
      align="center">
            <v-col
            :md="6"
            >
            <v-img
                :src="require('.././assets/logocorrige.png')"
                class="my-3"
                contain
                height="300"
            >
              <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
              </template>
            </v-img>
            </v-col>
            <v-col
            :md="6"
            class='text-center'          
            >
            <div class="contact_titre">      
            <span class="contact_title">Pour me contacter</span>
            </div>
            <br>
              <v-icon class="phone-icon" color="blue" x-large>phone</v-icon> 
              <a class ="phone-a" href="tel:+32476265226">0476 26 52 26</a>
              <br>
              <v-icon class="mail-icon" color="blue" x-large>mail</v-icon> 
              <a class="mail-a" href="mailto:info@sf-elec.be">info@sf-elec.be</a>
              <br>
              <v-icon class="facebook-icon" color="blue" x-large>facebook</v-icon>
              <a class="facebook-a" target="_blank" href="https://www.facebook.com/SF-ELEC-103913258038817">SF ELEC</a>
              <br><br>
              <!-- https://www.facebook.com/SF-ELEC-103913258038817 -->
            </v-col>
      </v-row>
      </div>

      <!-- </v-container> -->

      <!-- </div> -->
    </v-main>

  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default {
  name: 'Home',

  components: {
    
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>

.contact_titre::after {
    content: "";
    height: 4px;
    background: #0072A7;
    border-radius: 50px;
    display: block;
    width: 35px;
    margin: 10px 0 0 48%;
}

.contact_title{
  font-family: 'Jura', sans-serif;
  font-weight: bold;
}

.important-txt{
  color:#3D6F9D;
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
}

/* .text-info{
  margin-left: 100px;
  margin-right: 100px;

} */

.p1, .p2, .p4{
  background-color: rgb(226, 232, 236);
  /* padding-top: 5%;
  padding-bottom: 5%; */
  /* font-size: 20px; */
  width: 100%;
  /* padding: 12px; */
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: justify;
}

.p3{
  /* padding-top: 5%;
  padding-bottom: 5%; */
    width: 100%;
  /* padding: 12px; */
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: justify;

}


/* .pinfo{
  padding-top: 5%;
  padding-bottom: 5%;

} */

h1{
  font-family: 'Jura', sans-serif;
  font-weight: bold;
}

h2{
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
}

.title-p{
  color:#3D6F9D;
  font-family: 'Jura', sans-serif;
  font-weight: bold;

}

.second-title{
  color:#3D6F9D;
  margin-bottom: 30px;
}

.banner {
  position: relative;
  margin-bottom: 10px;
  /* top: 0;
  left: 0; */
}
.image {
    /* display: inline-block; */
    width: 60%;
    height: 60%;
    z-index: 2;
    top: 25%;
    left: 20%;
    position: absolute;
}

.image-mobile {
    /* display: inline-block; */
    width: 80%;
    height: 50%;
    z-index: 2;
    top: 20%;
    left: 10%;
    position: absolute;
}

.background {
    width: 100%;
    height: 100%;
    position: relative;
}
.icon{
    position: absolute;
    z-index: 3;
    /* left: 78%; */
    /* top: 85%; */
    /* float: right; */
    bottom: 0;
    right:0;
    margin-right:15px;
    margin-bottom:3px;
}

.mail-icon-banner, .phone-icon-banner{
margin-left:15px;
margin-right:5px;
}

.mail-icon, .phone-icon{
margin-left:15px;
margin-right:15px;
margin-bottom:3px;
}

.facebook-icon{
margin-right: 12px;
}

.mail-a:link, .phone-a:link, .facebook-a:link{
  color:#4b4b4b !important;
  font-weight: bold;
}

.facebook-a{
  margin-right:33px;
  color:#4b4b4b !important;
}

a:link{
  color:white;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

.video-seb {
  margin-top: 50px;
  margin-bottom: 50px;
}

.video-responsive { 
overflow:hidden; 
padding-bottom:56.25%; 
padding-top: 30px;
position:relative; 
height:0;
}

.video-responsive iframe, .video-responsive object, .video-responsive embed {
left:0; 
top:0; 
height:100%;
width:100%;
position:absolute;
}

@media (max-width: 959px) {
    .banner{
        margin-top: 70px;
    }   
}

@media (min-width: 768px) and (max-width: 768px) {
    .image-mobile{
      width:60%;
      height:60%;
      margin-left: 5%;
    }
}

@media (max-width: 320px){
  .image-mobile{
    width: 90%;
    height: 40%;
    top: 25%;
    left: 5%;
  }

  .icon{
    margin-right: 20px;
  }

  .phone-icon-banner, .mail-icon-banner{
    font-size: 30px !important;
  }

  .phone-texte-banner,.mail-texte-banner {
    font-size: 14px;
    font-weight: bold;
  }
}

@media (min-width: 360px) and (max-width: 375px){
  .icon{
    margin-left: 0px;
    margin-right: 30px;
  }
  .phone-icon-banner{
    margin-left: 0px;
  }
}
</style>
